import { useDisclosure } from "@chakra-ui/hooks";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";

export type DialogProps = {
  handleRemoveButtonClick: () => void;
  buttonLoading: boolean;
};

export default function RemoveBackerDialog(props: DialogProps) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { handleRemoveButtonClick, buttonLoading } = props;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Remove Backer
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You cannot undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              fontWeight="semibold"
              _hover={{
                bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                textColor: "white",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              bg="green.400"
              ml={3}
              fontWeight="semibold"
              _hover={{
                bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                textColor: "white",
              }}
              loadingText="Please wait..."
              isLoading={buttonLoading}
              onClick={() => {
                handleRemoveButtonClick();
              }}
            >
              Remove Backer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
