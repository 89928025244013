import {
  Box,
  Button,
  Divider,
  Flex,
  Img,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FiGift } from "react-icons/fi";
import useSWR from "swr";
import filter_icon from "../../../images/filter_icon.png";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../Loader";
import ProfilesCard from "../../profiles-cards";
import { InvitationStatus, RequestsProps, FetchState, User } from "../../Types";
import NetworkSideBar from "../network/NetworkSideBar";
import { getUserById } from "../../../api/api";
import { AlertDialogBox } from "../../dialog-box/VerificationAlert";

export function NoRequests() {
  return (
    <Stack
      spacing={5}
      direction="column"
      pb={{ base: "1em", md: "none", lg: "1.68375em" }}
      align="center"
    >
      <Stack
        spacing={5}
        direction="column"
        pb={{ base: "1em", md: "none", lg: "1.68375em" }}
        align="center"
        maxW="34em"
      >
        <FiGift size="6em" color="#48BB78" />
        <Text fontSize="3xl" textAlign="center">
          You have no pending invitations.
        </Text>
        <Text textAlign="center">
          Invite more friends to join Thebe and earn extra points.
        </Text>
        <Link to="/dashboard/network/invite">
          <Button
            size="lg"
            bg="green.400"
            type="submit"
            _hover={{
              bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
            }}
          >
            Invite Friends
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
}

function Requests() {
  const [statusValue, setStatus] = useState(InvitationStatus.ACTIVE);
  const [requests, setRequests] = useState<Array<RequestsProps>>([]);
  const [fetchState, setFetchState] = useState(FetchState.INITIAL);
  const [tokenError, setTokenError] = useState(FetchState.INITIAL);
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  const { data: userDetailsData, error: apiError } = useSWR(
    loggedInUser?.userId && loggedInUser.userId,
    getUserById,
    {
      suspense: false,
      revalidateIfStale: true,
      refreshInterval: 4000,
    },
  );
  const [userDetails, setUserDetails] = useState<User>(userDetailsData?.data);
  useEffect(() => {
    if (userDetailsData && userDetailsData.data) {
      setUserDetails(userDetailsData);
    }
  }, [userDetailsData, apiError]);

  const redirectToProfile = (path = "/dashboard/profile") => {
    navigate(path, { replace: true });
  };

  const getRequests = useCallback(() => {
    setFetchState(FetchState.LOADING);
    if (loggedInUser?.userId) {
      const token = localStorage.getItem("authToken");
      axios
        .get(`/backers/${loggedInUser?.userId}/${statusValue}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((responseData) => {
          const { data } = responseData.data;
          if (responseData.data.success === true) {
            setRequests(data);
            setFetchState(FetchState.SUCCESS);
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response?.status === 400) {
            setTokenError(FetchState.ERROR);
            setFetchState(FetchState.INITIAL);
          } else {
            setFetchState(FetchState.ERROR);
          }
        });
    }
  }, [loggedInUser?.userId, statusValue]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const displayCalculatedActiveConnections = () => {
    return (
      <Flex direction="row">
        <Text
          bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
          bgClip="text"
          fontSize="4xl"
          fontWeight="bold"
        >
          {requests && requests.length === 1 ? (
            <Text>{requests.length} Backer</Text>
          ) : (
            <Text>{requests.length} Backers</Text>
          )}
        </Text>
      </Flex>
    );
  };

  const showInvitationRequests = () => {
    return (
      <Box w="full">
        {requests && requests.length === 0 ? (
          <NoRequests />
        ) : (
          <Stack
            direction={{ base: "column-reverse", md: "row" }}
            justifyContent="space-between"
            w="full"
            spacing={8}
          >
            <Stack w="full" pb={4}>
              <Stack
                rounded="md"
                border="1px dashed #6D7886"
                bgColor="#EAEDF0"
                p={6}
                spacing={4}
                justifyContent={{ base: "flex-start", md: "space-around" }}
              >
                {displayCalculatedActiveConnections()}
                {requests &&
                  requests.map((request: RequestsProps) => {
                    const {
                      requestId,
                      backerAccountId,
                      borrowerAccountId,
                      status,
                      backerAccount,
                    } = request;
                    return (
                      <>
                        <Divider />
                        <ProfilesCard
                          key={requestId}
                          requestId={requestId}
                          status={status}
                          backerAccountId={backerAccountId}
                          borrowerAccountId={borrowerAccountId}
                          backerAccount={{
                            user: {
                              userId: backerAccount.user.userId,
                              firstname: backerAccount.user.firstname,
                              lastname: backerAccount.user.lastname,
                            },
                          }}
                          activeConnection={false}
                        />
                      </>
                    );
                  })}
              </Stack>
            </Stack>
            <NetworkSideBar />
          </Stack>
        )}
      </Box>
    );
  };

  const redirectToLoginPage = (path = "/login") => {
    setTimeout(() => {
      navigate(path, { replace: true });
      window.location.reload();
    }, 2500);
  };

  const showConnectionFilters = () => {
    return (
      <Flex
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
        p={4}
        w="full"
      >
        <Text
          textAlign="center"
          pl={{ base: "none", md: 2 }}
          pb={2}
          fontWeight="semibold"
        >
          {statusValue === "Pending" ? "Pending Requests" : "Accepted Requests"}
        </Text>
        <Stack pt={{ base: 3, md: 0 }} direction="row">
          <Select
            bgColor="green.300"
            icon={<Img src={filter_icon} alt="filter icon" />}
            borderColor="green.300"
            value={statusValue}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setStatus(e.target.value as InvitationStatus)
            }
          >
            {Object.values(InvitationStatus).map((value) => {
              return (
                <option
                  style={{ color: "#6D7886", backgroundColor: "#EAEDF0" }}
                  key={value}
                  value={value}
                >
                  {value}
                </option>
              );
            })}
          </Select>
        </Stack>
      </Flex>
    );
  };

  return (
    <Box
      pl={{ base: "2.8em", lg: "14.6em" }}
      alignItems="center"
      pt="8.6em"
      pr="2em"
      w="full"
    >
      <VStack pb={3}>
        <>
          {userDetails &&
            (userDetails.data.studentId === "0000000000" ||
              userDetails.data.userVerificationStatus === "Unverified") && (
              <AlertDialogBox
                handleClick={() => {
                  redirectToProfile();
                }}
              />
            )}
          {showConnectionFilters()}
          {fetchState === FetchState.LOADING && <Loader />}
          {fetchState === FetchState.ERROR && <NoRequests />}
          {tokenError === FetchState.ERROR && redirectToLoginPage()}
          {fetchState === FetchState.SUCCESS && showInvitationRequests()}
        </>
      </VStack>
    </Box>
  );
}
export default Requests;
