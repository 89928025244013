import { Box, Center, Spinner } from "@chakra-ui/react";

export default function Loader() {
  return (
    <Box
      mt={{ base: "2.2em", md: 10 }}
      w={{ base: 300, md: "auto" }}
      alignItems="center"
    >
      <Center>
        <Spinner
          thickness="0.3em"
          speed="0.65s"
          emptyColor="gray.200"
          color="green.500"
          size="xl"
        />
      </Center>
    </Box>
  );
}
