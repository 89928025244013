export enum FetchState {
  INITIAL = "initial",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

export type User = {
  data: {
    userId: string;
    username: string;
    firstname: string;
    lastname: string;
    gender: string;
    cityOrTown: string;
    dateOfBirth: string;
    address: string;
    userType: string;
    email: string;
    university: string;
    studentIdPhoto: string;
    studentId: string;
    whatsAppNumber: string;
    country: string;
    region: string;
    userStatus: string;
    userVerificationStatus: string;
    kycComplete: boolean;
    firstTimeLoggingIn: boolean;
    backOfProofOfIdentity: string;
    frontOfProofOfIdentity: string;
    userPicture: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type DashboardProps = {
  backerPoints: number;
  availableBackerPoints: number;
  totalAmountBorrowed: number;
  trustPoints: number;
  availableTrustPoints: number;
  walletAddress: string;
  userStatus: string;
  userVerificationStatus: string;
};

export type AgentDetails = {
  agentId: string;
  agentStatus: string;
  proofOfIdentity: string;
  proofOfResidence: string;
  kycDocumentPhoto: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  user: {
    userId: string;
    username: string;
    firstname: string;
    lastname: string;
    gender: string;
    cityOrTown: string;
    dateOfBirth: string;
    identificationType: string;
    identificationNumber: string;
    address: string;
    userType: string;
    email: string;
    whatsAppNumber: string;
    country: string;
    region: string;
    userStatus: string;
    kycComplete: boolean;
    userVerificationStatus: string;
    firstTimeLoggingIn: boolean;
    backOfProofOfIdentity: string;
    frontOfProofOfIdentity: string;
    userPicture: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type UsersDetails = {
  userId: string;
  username: string;
  firstname: string;
  lastname: string;
  gender: string | null;
  cityOrTown: string | null;
  dateOfBirth: string | null;
  identificationType: string;
  identificationNumber: string;
  address: string | null;
  userType: string;
  email: string;
  whatsAppNumber: string;
  studentId: string;
  country: string | null;
  region: string | null;
  userStatus: string;
  kycComplete: boolean;
  userVerificationStatus: string;
  firstTimeLoggingIn: boolean;
  backOfProofOfIdentity: string;
  frontOfProofOfIdentity: string;
  userPicture: string;
  createdAt: string;
  updatedAt: string;
  accounts: [
    {
      accountId: string;
      trustPoints: number;
      availableTrustPoints: number;
      backerPoints: number;
      availableBackerPoints: number;
      createdAt: string;
      updatedAt: string;
      userId: string;
    },
  ];
};

export type AdminAgentDetails = {
  agentId: string;
  agentStatus: string;
  proofOfIdentity: string;
  proofOfResidence: string;
  kycDocumentPhoto: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  user: {
    userId: string;
    username: string;
    firstname: string;
    gender: string;
    cityOrTown: string;
    dateOfBirth: string;
    identificationType: string;
    identificationNumber: string;
    address: string;
    userType: string;
    email: string;
    whatsAppNumber: string;
    country: string;
    region: string;
    userStatus: string;
    kycComplete: boolean;
    userVerificationStatus: string;
    firstTimeLoggingIn: boolean;
    backOfProofOfIdentity: string;
    frontOfProofOfIdentity: string;
    userPicture: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type AdminUserDetails = {
  userId: string;
  username: string;
  firstname: string;
  lastname: string;
  gender: string;
  cityOrTown: string;
  dateOfBirth: string;
  identificationType: string;
  identificationNumber: string;
  address: string;
  userType: string;
  email: string;
  whatsAppNumber: string;
  studentId: string;
  studentIdPhoto: string;
  university: string;
  country: string;
  region: string;
  userStatus: string;
  kycComplete: boolean;
  userVerificationStatus: string;
  firstTimeLoggingIn: boolean;
  backOfProofOfIdentity: string;
  frontOfProofOfIdentity: string;
  userPicture: string;
  createdAt: string;
  updatedAt: string;
  accounts: {
    accountId: string;
    trustPoints: number;
    availableTrustPoints: number;
    backerPoints: number;
    availableBackerPoints: number;
    createdAt: string;
    updatedAt: string;
    userId: string;
  };
};

export type AgentLoanDetails = {
  loan: {
    loanId: string;
    amount: number;
    applicationDate: string;
    dueDate: string;
    closeDate: string;
    rate: number;
    agentProofOfPaymentLink: string;
    proofOfPaymentLink: string;
    borrowerAccountId: string;
    paymentMethod: string;
    account: {
      accountId: string;
      trustPoints: number;
      availableTrustPoints: number;
      backerPoints: number;
      availableBackerPoints: number;
      activeWalletAddress: string;
      user: {
        userId: string;
        firstname: string;
        lastname: string;
        whatsAppNumber: string;
        backOfProofOfIdentity: string;
        frontOfProofOfIdentity: string;
        userPicture: string;
      };
    };
    loanStatus: {
      status: string;
    };
    agent: {
      user: {
        userId: string;
        firstname: string;
        lastname: string;
        whatsAppNumber: string;
        email: string;
      };
    };
  };
  loanBackers: [
    {
      loanBackerId: string;
      stakeDate: string;
      backerPointsStaked: number;
      backerPointsEarned: number;
      commissionEarned: number;
      account: {
        accountId: string;
        trustPoints: number;
        availableTrustPoints: number;
        backerPoints: number;
        availableBackerPoints: number;
        activeWalletAddress: string;
        user: {
          userId: string;
          firstname: string;
          lastname: string;
        };
      };
    },
  ];
};

export type UserLoans = {
  loanId: string;
  amount: number;
  rate: number;
  principal: number;
  applicationDate: string;
  startDate: string;
  dueDate: string | number;
  closeDate: string;
  trustPointsStaked: number;
  backerPointsStaked: number;
  totalTrustPointsEarned: number;
  totalBackerPointsEarned: number;
  lastPaymentStatus: string;
  proofOfPaymentLink: string;
  paymentMethod: string;
  createdAt: string;
  updatedAt: string;
  borrowerAccountId: string;
  loanStatusId: string;
  agentId: string;
  loanStatus: {
    loanStatusId: string;
    status: string;
    createdAt: string;
    updatedAt: string;
  };
  account: {
    accountId: string;
    trustPoints: number;
    availableTrustPoints: number;
    backerPoints: number;
    availableBackerPoints: number;
    createdAt: string;
    updatedAt: string;
    userId: string;
    user: {
      userId: string;
      firstname: string;
      lastname: string;
      whatsAppNumber: string;
      email: string;
    };
  };
};

export type UserLoanHistoryProps = {
  loanId: string;
  amount: number;
  rate: number;
  principal: number;
  applicationDate: string;
  startDate: string;
  dueDate: string | number;
  closeDate: string;
  trustPointsStaked: number;
  backerPointsStaked: number;
  totalTrustPointsEarned: number;
  totalBackerPointsEarned: number;
  lastPaymentStatus: string;
  proofOfPaymentLink: string;
  agentProofOfPaymentLink: string;
  paymentMethod: string;
  createdAt: string;
  updatedAt: string;
  borrowerAccountId: string;
  loanStatusId: string;
  agentId: string;
  loanStatus: {
    loanStatusId: string;
    status: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type PaginationProps = {
  count: number;
  totalLoans: number;
  page: number;
  pageSize: number;
};

export type UserLoansBacked = {
  loanStatus: string;
  loanId: string;
  amount: number;
  startDate: string;
  dueDate: string;
  daysLeft: number;
  repayment: number;
};

export type PendingInvites = {
  requestId: string;
  status: string;
  backerAccountId: string;
  borrowerAccountId: string;
  borrowerAccount: {
    trustPoints: number;
    availableTrustPoints: number;
    backerPoints: number;
    availableBackerPoints: number;
    userId: string;
    user: {
      firstname: string;
      lastname: string;
    };
  };
  handleAcceptInvite: () => void;
  handleRejectInvite: () => void;
};

export type BackersList = {
  requestId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  backerAccountId: string;
  borrowerAccountId: string;
  backerAccount: {
    accountId: string;
    trustPoints: number;
    availableTrustPoints: number;
    backerPoints: number;
    availableBackerPoints: number;
    createdAt: string;
    updatedAt: string;
    userId: string;
    user: {
      firstname: string;
      lastname: string;
      whatsAppNumber: string;
      email: string;
    };
  };
};

export type IncomingInvites = {
  requestId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  backerAccountId: string;
  borrowerAccountId: string;
  borrowerAccount: {
    accountId: string;
    trustPoints: number;
    availableTrustPoints: number;
    backerPoints: number;
    availableBackerPoints: number;
    createdAt: string;
    updatedAt: string;
    userId: string;
    user: {
      firstname: string;
      lastname: string;
      whatsAppNumber: string;
      email: string;
    };
  };
};

export type ActiveInvitesProps = {
  requestId: string;
  status: string;
  backerAccountId: string;
  borrowerAccountId: string;
  borrowerAccount: {
    user: {
      userId: string;
      firstname: string;
      lastname: string;
    };
  };
  activeConnection: boolean;
};

export type RequestsProps = {
  requestId: string;
  status: string;
  backerAccountId: string;
  borrowerAccountId: string;
  backerAccount: {
    user: {
      userId: string;
      firstname: string;
      lastname: string;
    };
  };
  activeConnection: boolean;
};

export type BackersDetails = {
  backers: {
    requestId: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    backerAccountId: string;
    borrowerAccountId: string;
    backerAccount: {
      accountId: string;
      trustPoints: number;
      availableTrustPoints: number;
      backerPoints: number;
      availableBackerPoints: number;
      createdAt: string;
      updatedAt: string;
      userId: string;
    };
  };
  loanInfo: [
    {
      loanId: string;
      amount: number;
      rate: number;
      principal: number;
      applicationDate: string;
      startDate: string;
      dueDate: string;
      closeDate: string;
      trustPointsStaked: number;
      backerPointsStaked: number;
      totalTrustPointsEarned: number;
      totalBackerPointsEarned: number;
      lastPaymentStatus: string;
      proofOfPaymentLink: string;
      agentProofOfPaymentLink: string;
      paymentMethod: string;
      createdAt: string;
      updatedAt: string;
      borrowerAccountId: string;
      loanStatusId: string;
      agentId: string;
      loanStatus: {
        loanStatusId: string;
        status: string;
        createdAt: string;
        updatedAt: string;
      };
    },
  ];
  loansBacked: [
    {
      loanBackerId: string;
      stakeDate: string;
      backerPointsStaked: number;
      backerPointsEarned: number;
      commissionEarned: number;
      createdAt: string;
      updatedAt: string;
      accountId: string;
      loanId: string;
      loan: {
        loanId: string;
        amount: number;
        rate: number;
        principal: number;
        applicationDate: string;
        startDate: string;
        dueDate: string;
        closeDate: string;
        trustPointsStaked: number;
        backerPointsStaked: number;
        totalTrustPointsEarned: number;
        totalBackerPointsEarned: number;
        lastPaymentStatus: string;
        proofOfPaymentLink: string;
        agentProofOfPaymentLink: string;
        paymentMethod: string;
        createdAt: string;
        updatedAt: string;
        borrowerAccountId: string;
        loanStatusId: string;
        agentId: string;
        loanStatus: {
          loanStatusId: string;
          status: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    },
  ];
};

export type PeopleBackedDetails = {
  borrower: {
    requestId: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    backerAccountId: string;
    borrowerAccountId: string;
    borrowerAccount: {
      accountId: string;
      trustPoints: number;
      availableTrustPoints: number;
      backerPoints: number;
      availableBackerPoints: number;
      createdAt: string;
      updatedAt: string;
      userId: string;
      user: {
        userId: string;
        firstname: string;
        lastname: string;
      };
    };
  };
  loanInfo: [
    {
      loanId: string;
      amount: number;
      rate: number;
      principal: number;
      applicationDate: string;
      startDate: string;
      dueDate: string;
      closeDate: string;
      trustPointsStaked: number;
      backerPointsStaked: number;
      totalTrustPointsEarned: number;
      totalBackerPointsEarned: number;
      lastPaymentStatus: string;
      proofOfPaymentLink: string;
      agentProofOfPaymentLink: string;
      paymentMethod: string;
      createdAt: string;
      updatedAt: string;
      borrowerAccountId: string;
      loanStatusId: string;
      agentId: string;
      loanStatus: {
        loanStatusId: string;
        status: string;
        createdAt: string;
        updatedAt: string;
      };
    },
  ];
  loansBacked: [
    {
      loanBackerId: string;
      stakeDate: string;
      backerPointsStaked: number;
      backerPointsEarned: number;
      commissionEarned: number;
      createdAt: string;
      updatedAt: string;
      accountId: string;
      loanId: string;
      loan: {
        loanId: string;
        amount: number;
        rate: number;
        principal: number;
        applicationDate: string;
        startDate: string;
        dueDate: string;
        closeDate: string;
        trustPointsStaked: number;
        backerPointsStaked: number;
        totalTrustPointsEarned: number;
        totalBackerPointsEarned: number;
        lastPaymentStatus: string;
        proofOfPaymentLink: string;
        agentProofOfPaymentLink: string;
        paymentMethod: string;
        createdAt: string;
        updatedAt: string;
        borrowerAccountId: string;
        loanStatusId: string;
        agentId: string;
        loanStatus: {
          loanStatusId: string;
          status: string;
          createdAt: string;
          updatedAt: string;
        };
      };
    },
  ];
};

export type AdminStatistics = {
  loanCount: {
    totalActiveLoans: number;
    totalPaidLoans: number;
    totalFailedLoans: number;
    totalDefaultedLoans: number;
    totalCashOutLoans: number;
    totalCashInLoans: number;
    totalRejectedLoans: number;
    totalLoansOverDue: number;
  };
  totalAmountGivenOut: number;
  totalRevenue: number;
  totalTrustPointsAmount: number;
  totalBackers: number;
  usersCount: {
    totalAgents: number;
    totalAdmins: number;
    totalBorrowers: number;
    totalVerifiedBorrowers: number;
    totalUnVerifiedBorrowers: number;
  };
};

export enum InvitationStatus {
  ACTIVE = "Active",
  PENDING = "Pending",
}

export enum UserAccountStatus {
  ACTIVE = "Active",
  PENDING = "Pending",
}

export enum UserVerificationStatus {
  UNVERIFIED = "Unverified",
  VERIFIED = "Verified",
}

export enum AgentStatus {
  ACTIVE = "Active",
  PENDING = "Pending",
  BLOCKED = "Blocked",
  REJECTED = "Rejected",
}
