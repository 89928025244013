export enum LoanStatus {
  ACTIVE = "Active",
  PENDING = "Pending_Approval",
  REJECTED = "Rejected",
  ROLLOVER = "Rolled_Over",
  PAID = "Paid",
  PAID_WEEK_ONE = "Paid_Week_One",
  PAID_WEEK_TWO = "Paid_Week_Two",
  PAID_WEEK_THREE = "Paid_Week_Three",
  LATE = "Loan_Overdue",
  DEFAULTED = "Account_Closed",
  PROCESSING = "Processing",
  PROCESS_CASHIN = "Process_CashIn",
  PROCESS_CASHOUT = "Process_CashOut",
  FAILED = "Failed",
}

export enum LoanPaymentStatus {
  SUCCESS = "Successful",
  FAILED = "Failed",
}
