import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  Hide,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { RequestsProps } from "../Types";
import BackersCards from "../dashboard/backers-card";
import ViewPeopleBacked from "../dashboard/view-people-backed";
import RemoveBackerDialog from "../dialog-box/RemoveBackerDialog";
import { userRemoveBacker } from "../../api/api";
import useAuth from "../../hooks/useAuth";

export default function ProfilesCard(data: RequestsProps) {
  const [selectedBackerId, setSelectedBackerId] = useState("");
  const [selectedBorrowerId, setSelectedBorrowerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackingToggle, setOpenBackingToggle] = useState(false);
  const { loggedInUser } = useAuth();
  const toast = useToast();
  const {
    activeConnection,
    status,
    backerAccount,
    backerAccountId,
    borrowerAccountId,
  } = data;

  const displayAcceptToast = () => {
    toast({
      position: "top",
      title: "You Successfully removed a backer from your network!",
      status: "success",
      duration: 2500,
      isClosable: true,
    });
  };

  const displayAcceptToastError = (errorMessage: string) => {
    toast({
      position: "top",
      title: "Failed to remove backer!",
      description: `${errorMessage}`,
      status: "error",
      duration: 2500,
      isClosable: true,
    });
  };

  const removeBackerFromNetwork = async (backerId: string) => {
    setLoading(true);
    const result = await userRemoveBacker(
      backerId,
      loggedInUser?.userId && loggedInUser.userId,
    );
    if (result.success === true) {
      setLoading(false);
      setOpenDialog(false);
      window.location.reload();
      return displayAcceptToast();
    }
    setLoading(false);
    return displayAcceptToastError(result as string);
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }}
      >
        <Flex
          alignItems={{ base: "center", md: "none" }}
          justify={{ base: "center", md: "none" }}
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            h="fit-content"
            w="fit-content"
            rounded="full"
            p={1}
            bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
          >
            <Avatar
              size="md"
              color="#FFFFFF"
              name={`${backerAccount.user.firstname} ${backerAccount.user.lastname}`}
              bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
            >
              <AvatarBadge
                boxSize="0.7em"
                borderColor={
                  status && status === "Active" ? "green.500" : "red.500"
                }
                bg={status && status === "Active" ? "green.500" : "red.500"}
              />
            </Avatar>
          </Flex>
          <Flex direction="column" flex={1}>
            <Flex direction="row" px={4}>
              <Text pr={3} fontSize="lg" fontWeight="semibold">
                {`${backerAccount.user.firstname}`}
              </Text>
              <Text fontSize="lg" fontWeight="semibold">
                {`${backerAccount.user.lastname}`}
              </Text>
            </Flex>
            <Text
              fontWeight="normal"
              px={4}
              textAlign={{ base: "center", md: "start" }}
            >
              {activeConnection && activeConnection === true
                ? "Connected"
                : `${status}`}
            </Text>
          </Flex>
        </Flex>
        <Stack
          direction={{ base: "column", md: "row" }}
          p={3}
          justify="center"
          alignItems={{ base: "none", md: "center" }}
        >
          {activeConnection && activeConnection === true ? (
            <>
              <Hide>
                <Button
                  bg="transparent"
                  border="1px"
                  borderColor="green.400"
                  fontWeight="semibold"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                    textColor: "white",
                  }}
                >
                  Pause backing
                </Button>
              </Hide>
              <Button
                bg="green.400"
                fontWeight="semibold"
                _hover={{
                  bgGradient:
                    "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                  textColor: "white",
                }}
                onClick={() => {
                  // console.log({ borrowerAccountId });
                  setSelectedBorrowerId(borrowerAccountId);
                  setOpenBackingToggle(true);
                }}
              >
                View Profile
              </Button>
            </>
          ) : (
            <Stack spacing={2} direction={{ md: "row", base: "column" }}>
              <Button
                bg="transparent"
                border="1px"
                borderColor="green.400"
                fontWeight="semibold"
                _hover={{
                  bgGradient:
                    "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                  textColor: "white",
                }}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                Remove
              </Button>
              {openToggle ? (
                <Button
                  bg="transparent"
                  px={4}
                  border="1px"
                  borderColor="green.400"
                  fontWeight="semibold"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                    textColor: "white",
                  }}
                  onClick={() => {
                    setOpenToggle(false);
                  }}
                >
                  Close Profile
                </Button>
              ) : (
                <Stack spacing={2} direction={{ md: "row", base: "column" }}>
                  <Button
                    bg="green.400"
                    border="1px"
                    borderColor="green.400"
                    fontWeight="semibold"
                    _hover={{
                      bgGradient:
                        "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                      textColor: "white",
                    }}
                    onClick={() => {
                      setSelectedBackerId(backerAccountId);
                      setOpenToggle(true);
                    }}
                  >
                    View Profile
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Flex>
      {openToggle && (
        <BackersCards backerAccountId={selectedBackerId && selectedBackerId} />
      )}
      {openBackingToggle && selectedBorrowerId !== "" && (
        <ViewPeopleBacked
          isDisplayed={openBackingToggle && selectedBorrowerId !== ""}
          borrowerAccountId={selectedBorrowerId && selectedBorrowerId}
          onClose={() => {
            setOpenBackingToggle(false);
          }}
        />
      )}
      {openDialog && (
        <RemoveBackerDialog
          handleRemoveButtonClick={() => {
            removeBackerFromNetwork(backerAccount.user.userId);
          }}
          buttonLoading={loading}
        />
      )}
    </>
  );
}
