import { decodeToken } from "react-jwt";
import { AuthUser } from "../context/authentication/AuthContext";

interface AuthData {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  userType: string;
}

export default function convertTokenToUser(token: string): AuthUser | null {
  const authData = decodeToken<AuthData>(token);
  if (!authData) {
    return null;
  }
  const authUser: AuthUser = {
    userId: authData.userId,
    firstname: authData.firstName,
    phoneNumber: authData.phoneNumber,
    lastname: authData.lastName,
    email: authData.email,
    userRoleId: authData.userType,
    userType: authData.userType,
  };
  return authUser;
}
