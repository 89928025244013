import { Button, Flex, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NetworkSideBar() {
  return (
    <Flex
      h={{ base: "auto", md: "34em" }}
      maxW={{ base: "full", md: "14rem" }}
      flexDir="column"
      justifyContent="space-between"
      borderRadius="md"
      p={3}
      border="1px dashed #6D7886"
      bgColor="#EAEDF0"
      pos="sticky"
    >
      <Stack alignItems="center" w="full" p={2}>
        <Text color="green.300" fontWeight="semibold" textAlign="center" py={2}>
          Manage Requests
        </Text>
        <Link to="/dashboard/requests">
          <Button
            bg="transparent"
            _hover={{
              bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
              textColor: "white",
            }}
          >
            Sent Requests
          </Button>
        </Link>
      </Stack>
      <Stack alignItems="center" w="full">
        <Text color="green.300" fontWeight="semibold" textAlign="center" py={2}>
          Manage Connections
        </Text>
        <Link to="/dashboard/network">
          <Button
            bg="transparent"
            _hover={{
              bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
              textColor: "white",
            }}
          >
            Connections
          </Button>
        </Link>
      </Stack>
      <Stack
        p={3}
        border="1px"
        borderColor="green.300"
        borderRadius="md"
        alignItems="center"
      >
        <Text color="green.300" textAlign="center">
          Get more people to join Thebe and grow your network.
        </Text>
        <Link to="/dashboard/network/invite">
          <Button
            bg="green.400"
            _hover={{
              bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
              textColor: "white",
            }}
          >
            Invite People
          </Button>
        </Link>
      </Stack>
    </Flex>
  );
}
