import { Box, Center, Flex, Stack, Text } from "@chakra-ui/react";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { getBackerById } from "../../../api/api";
import { BackersDetails } from "../../Types";
import { LoanStatus } from "../../../utils/loanStatusEnum";
import Loader from "../../Loader";

export type BackersCardsProps = {
  backerAccountId: string;
};

export type BackersCardsData = {
  title: string;
  value: number;
};

export function LoansOverViewCard(Props: BackersCardsData) {
  const { title, value } = Props;
  return (
    <Box
      w="full"
      maxW="auto"
      alignItems="center"
      mt={{ base: 4, md: 0 }}
      border={{ base: "1px", md: "none" }}
      borderColor="#737679"
      borderRadius="1.2em"
      p={3}
    >
      <Center>
        <Text color="#737679" fontSize="4xl" fontWeight="bold" px={1}>
          {value}
        </Text>
      </Center>
      <Center py={3}>
        <Text fontSize="sm" fontWeight={600} color="#737679">
          {title}
        </Text>
      </Center>
    </Box>
  );
}

export default function BackersCards(props: BackersCardsProps) {
  const { backerAccountId } = props;

  const { data: backersData, error } = useSWR(
    `${backerAccountId}`,
    getBackerById,
  );

  const [backerDetails, setBackerDetails] =
    useState<BackersDetails>(backersData);

  useEffect(() => {
    if (backersData && backersData) {
      setBackerDetails(backersData);
    }
  }, [backersData, error]);

  const getAllPaidLoans = () => {
    if (backerDetails.loanInfo) {
      const loansPaidInWeeks = backerDetails.loanInfo.filter(
        (loan) =>
          loan.loanStatus.status === LoanStatus.PAID ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_ONE ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_TWO ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_THREE,
      );

      return loansPaidInWeeks.length;
    }
    return 0;
  };

  const getPaidBackedLoans = () => {
    if (backerDetails.loansBacked) {
      const loansPaidInWeeks = backerDetails.loansBacked.filter(
        (loan) =>
          loan.loan.loanStatus.status === LoanStatus.PAID ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_ONE ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_TWO ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_THREE,
      );

      return loansPaidInWeeks.length;
    }
    return 0;
  };

  return (
    <Stack>
      {!backerDetails && !error && <Loader />}
      {backerDetails && backerDetails && (
        <>
          <Flex direction="column" justify="center" justifyContent="center">
            <Box w="full" alignItems="center" rounded="md" p={2}>
              <Center>
                <Text
                  bgClip="text"
                  fontSize="5xl"
                  fontWeight="bold"
                  bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
                >
                  {backerDetails.backers.backerAccount &&
                    backerDetails.backers.backerAccount.trustPoints}
                  TP
                </Text>
              </Center>
              <Text
                fontSize="sm"
                textAlign="center"
                fontWeight={600}
                color="#737679"
              >
                Trust Points
              </Text>
            </Box>
          </Flex>
          <Stack
            justifyContent="space-between"
            spacing={{ base: 4, md: 4, lg: 6 }}
            direction={{ base: "column", md: "row" }}
            pb={{ base: "", md: "1em" }}
          >
            <LoansOverViewCard
              title="Loans Taken"
              value={backerDetails.loanInfo.length}
            />
            <LoansOverViewCard title="Loans Paid" value={getAllPaidLoans()} />
            <LoansOverViewCard
              title="Loans Backed"
              value={backerDetails.loansBacked.length}
            />
            <LoansOverViewCard
              title="Paid Backings"
              value={getPaidBackedLoans()}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
}
