import axios, { AxiosError } from "axios";
import { AgentStatus, InvitationStatus } from "../components/Types";
import { LoanStatus } from "../utils/loanStatusEnum";

const apiInstance = axios.create({
  baseURL: "https://thebe-api.azurewebsites.net/api/v1/",
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.data &&
      error.response.data
    ) {
      const { error: err } = error.response.data as {
        error: { message: string; name: string };
      };

      if (
        err.name === "JsonWebTokenError" ||
        err.name === "TokenExpiredError"
      ) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  },
);

function getToken(): string {
  return localStorage.getItem("authToken") as string;
}

const getUserById = async (userId: string) => {
  const users = await apiInstance
    .get(`/user/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data);
  return users;
};

const getUsers = async () => {
  const users = await apiInstance
    .get("/user-loans", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data);
  return users;
};
interface SearchRequestBody {
  searchTerm: string;
}

const searchUsers = async (search: string) => {
  const requestBody: SearchRequestBody = { searchTerm: search };

  const users = await apiInstance
    .post("/users/search", requestBody, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data);

  return users;
};

const getUserLoans = async () => {
  const users = await apiInstance
    .get("/users/", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data);
  return users;
};

const getUserDashboard = async () => {
  const data = apiInstance
    .get("/dashboard", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return {
          success: false,
          message: "Error getting dashboard data",
          data: [],
        };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your dashboard data",
        data: [],
      };
    });
  return data;
};

const getUserLoanHistoryData = async (userId: string) => {
  const data = apiInstance
    .get(`/user-loans/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  return data;
};

const getPendingBackersList = async (userId: string) => {
  const backersData = await apiInstance
    .get(`/invites/${userId}/${InvitationStatus.PENDING}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return backersData;
};

const getBackersList = async (userId: string) => {
  const backersData = await apiInstance
    .get(`/invites/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return backersData;
};

const getBorrowersInvitesList = async (userId: string) => {
  const backersData = await apiInstance
    .get(`/invites/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return backersData;
};

const getRequestedBackersList = async (userId: string) => {
  const backersData = await apiInstance
    .get(`/backers/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return backersData;
};

const getBorrowersBackersList = async (userId: string) => {
  const backersData = await apiInstance
    .get(`/${userId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return backersData;
};

const getBackerById = async (backerId: string) => {
  const backersData = await apiInstance
    .get(`/backers/${backerId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    .catch(() => null);
  return backersData;
};

const getBorrowerById = async (borrowerId: string) => {
  const borrowersData = await apiInstance
    .get(`/borrowers/${borrowerId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    .catch(() => null);
  return borrowersData;
};

const getAgentLoanHistoryData = async (userId: string) => {
  const paidLoans = apiInstance
    .get(`/agent/loans/${userId}/${LoanStatus.PAID}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const activeLoans = apiInstance
    .get(`/agent/loans/${userId}/${LoanStatus.ACTIVE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekOneLoans = apiInstance
    .get(`/agent/loans/${userId}/${LoanStatus.PAID_WEEK_ONE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekTwoLoans = apiInstance
    .get(`/agent/loans/${userId}/${LoanStatus.PAID_WEEK_TWO}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekThreeLoans = apiInstance
    .get(`/agent/loans/${userId}/${LoanStatus.PAID_WEEK_THREE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const lateLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.LATE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const defaultedLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.DEFAULTED}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  return {
    activeLoans: await activeLoans,
    paidLoans: await paidLoans,
    paidWeekOneLoans: await paidWeekOneLoans,
    paidWeekTwoLoans: await paidWeekTwoLoans,
    paidWeekThreeLoans: await paidWeekThreeLoans,
    lateLoans: await lateLoans,
    defaultedLoans: await defaultedLoans,
  };
};

const getAdminStatistics = async (adminId: string) => {
  const data = apiInstance
    .get(`/admin/${adminId}/analytics`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch(() => null);
  return data;
};

const getAdminLoanHistoryData = async (userId: string) => {
  const paidLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.PAID}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const activeLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.ACTIVE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekOneLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.PAID_WEEK_ONE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekTwoLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.PAID_WEEK_TWO}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const paidWeekThreeLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.PAID_WEEK_THREE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const lateLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.LATE}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const defaultedLoans = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.DEFAULTED}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const failedTxns = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.FAILED}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  const rejectedTxns = apiInstance
    .get(`/admin/transactions/${userId}/${LoanStatus.REJECTED}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return { success: false, message: "No loans found", data: [] };
      }
      return {
        success: false,
        message: "Something went wrong while fetching your loans",
        data: [],
      };
    });

  return {
    activeLoans: await activeLoans,
    paidLoans: await paidLoans,
    paidWeekOneLoans: await paidWeekOneLoans,
    paidWeekTwoLoans: await paidWeekTwoLoans,
    paidWeekThreeLoans: await paidWeekThreeLoans,
    lateLoans: await lateLoans,
    defaultedLoans: await defaultedLoans,
    failedTxns: await failedTxns,
    rejectedTxns: await rejectedTxns,
  };
};

const getUserLoanDetails = async (loanId: string) => {
  const data = apiInstance
    .get(`/loans/${loanId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
    .catch(() => null);
  return data;
};

const getLoansToBeDisbursed = async (api: string) => {
  const mData = apiInstance
    .get(`${api}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return mData;
};

const getLoansToBeRepaid = async (api: string) => {
  const mData = apiInstance
    .get(`${api}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return mData;
};

const getAgentsDetails = async (adminId: string) => {
  const agentDdata = await apiInstance
    .get(`/admin/${adminId}/agents`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return agentDdata;
};

const getUsersDetails = async (page: number) => {
  const userdata = await apiInstance
    .get(`/users/${page}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return userdata;
};

const getSingleUserDetails = async (adminId: string) => {
  const userData = await apiInstance
    .get(`/admin/${adminId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return userData;
};

const getSingleAgentDetails = async (adminId: string) => {
  const agentDdata = await apiInstance
    .get(`/admin/${adminId}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return agentDdata;
};

const getAgentApplications = async (adminId: string) => {
  const agentDdata = await apiInstance
    .get(`admin/${adminId}/agents/${AgentStatus.PENDING}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return data;
    })
    // eslint-disable-next-line
    .catch((error: any) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      return error;
    });
  return agentDdata;
};

const agentDisburseLoan = async (
  userLoanId: string,
  agentId: string,
  proofOfPayment: string,
) => {
  const data = await apiInstance
    .post(
      `/agents/${agentId}/processCashOut`,
      {
        loanId: userLoanId,
        proofOfPayment,
        agentId,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const adminDisburseLoan = async (
  userLoanId: string,
  adminId: string,
  proofOfPayment: string,
) => {
  const data = await apiInstance
    .post(
      `/admin/${adminId}/processCashOut`,
      {
        loanId: userLoanId,
        proofOfPayment,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const agentRejectLoanDisbursement = async (
  userLoanId: string,
  message: string,
  agentId: string,
) => {
  const data = await apiInstance
    .post(
      `/agents/${agentId}/rejectCashOut`,
      {
        loanId: userLoanId,
        message,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const adminApproveAgent = async (
  adminId: string | undefined,
  userId: string,
) => {
  const data = await apiInstance
    .put(
      `/admin/${adminId}/agent/accept`,
      { userId },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const adminRejectAgent = async (
  adminId: string | undefined,
  userId: string,
  message: string,
) => {
  const data = await apiInstance
    .put(
      `/admin/${adminId}/agent/reject`,
      { userId, message },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const adminVerifyUser = async (
  adminId: string | undefined,
  userId: string,
  status: string,
  message: string,
) => {
  const data = await apiInstance
    .put(
      `/admin/${adminId}/verify-user`,
      { borrowerId: userId, status, message },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const userAcceptInvite = async (
  userId: string | undefined,
  askingUserId: string,
) => {
  const data = await apiInstance
    .put(
      "/accept-backers",
      {
        backerId: userId,
        borrowerId: askingUserId,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

const userRemoveBacker = async (
  userId: string,
  askingUserId: string | undefined,
) => {
  const data = await apiInstance
    .post(
      "/remove-backer",
      {
        backerId: userId,
        borrowerId: askingUserId,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response?.data) {
        const err = (error.response.data as { error: string }).error;
        return err;
      }
      return {
        success: false,
        message: "Something went wrong while removing backer",
        data: [],
      };
    });

  return data;
};

const userRejectInvite = async (
  userId: string | undefined,
  askingUserId: string,
) => {
  const data = await apiInstance
    .put(
      "/reject-backers",
      {
        backerId: userId,
        borrowerId: askingUserId,
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((response) => response.data)
    .catch(() => null);

  return data;
};

export {
  getUserById,
  getUsers,
  searchUsers,
  getUserLoans,
  getUserLoanDetails,
  getUserDashboard,
  getUserLoanHistoryData,
  getBackersList,
  getBorrowersInvitesList,
  getBorrowersBackersList,
  getPendingBackersList,
  getRequestedBackersList,
  getBackerById,
  getBorrowerById,
  getAgentLoanHistoryData,
  getAdminLoanHistoryData,
  getAdminStatistics,
  getLoansToBeDisbursed,
  getLoansToBeRepaid,
  getAgentsDetails,
  getUsersDetails,
  getSingleUserDetails,
  getSingleAgentDetails,
  getAgentApplications,
  userAcceptInvite,
  userRejectInvite,
  userRemoveBacker,
  adminApproveAgent,
  adminRejectAgent,
  adminVerifyUser,
  agentDisburseLoan,
  adminDisburseLoan,
  agentRejectLoanDisbursement,
  apiInstance,
};
