import {
  Flex,
  Button,
  Img,
  Spacer,
  Show,
  Drawer,
  DrawerOverlay,
  useDisclosure,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Box,
  IconButton,
  VStack,
  Hide,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { GiHamburgerMenu } from "react-icons/gi";

import useAuth from "../../hooks/useAuth";
import unmasked_logo from "../../images/Thebe-logo-wide.png";

export default function Navbar() {
  const { isLoggedIn, signOut } = useAuth();
  const [isTransparent, setIsTransparent] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });

  useEffect(() => {
    if (isLoggedIn) {
      window.location.reload();
    }
  }, [isLoggedIn]);

  const showMobileMenu = () => {
    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent bg="#222">
          <DrawerHeader pos="sticky" zIndex="popover" textColor="#49B97A">
            <DrawerCloseButton />
          </DrawerHeader>

          <DrawerBody textColor="#49B97A" py="8">
            <VStack spacing="4" w="full">
              <HashLink to="/#how-it-works">
                <Button
                  bg="transparent"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                    colorScheme: "whiteAlpha",
                  }}
                  onClick={onClose}
                >
                  How it works
                </Button>
              </HashLink>
              {!isLoggedIn && (
                <Link to="login">
                  <Button
                    mx={{ md: "10" }}
                    bg="transparent"
                    border="2px"
                    borderColor="green.400"
                    _hover={{
                      bgGradient:
                        "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                      textColor: "white",
                    }}
                    onClick={onClose}
                  >
                    Log in
                  </Button>
                </Link>
              )}
              {!isLoggedIn && (
                <Link to="/create-profile">
                  <Button w="full" bg="transparent" onClick={onClose}>
                    Sign up
                  </Button>
                </Link>
              )}

              {isLoggedIn && (
                <Button
                  onClick={() => {
                    onClose();
                    signOut();
                  }}
                  bg="green.400"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                  }}
                >
                  Log Out
                </Button>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

  const showDesktopMenu = () => {
    return (
      <Flex w="full">
        {/* Centered Menu Items */}
        <Flex direction={{ sm: "column", md: "row" }} w="full">
          <Hide>
            <Link to="/">
              <Button
                bg="transparent"
                _hover={{
                  bgGradient:
                    "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                }}
              >
                Borrow
              </Button>
            </Link>
          </Hide>
        </Flex>
        <Spacer />
        {/* Right Menu Items */}
        <Flex direction={{ sm: "column", md: "row" }}>
          <HashLink to="/#how-it-works">
            <Button
              bg="transparent"
              _hover={{
                bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                textColor: "white",
              }}
            >
              How it works
            </Button>
          </HashLink>

          {!isLoggedIn && (
            <>
              <Link to="login">
                <Button
                  mx={{ md: "10" }}
                  bg="transparent"
                  border="2px"
                  borderColor="green.400"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                    textColor: "white",
                  }}
                >
                  Log in
                </Button>
              </Link>
              <Link to="/create-profile">
                <Button
                  bg="green.400"
                  _hover={{
                    bgGradient:
                      "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                    textColor: "white",
                  }}
                >
                  Sign up
                </Button>
              </Link>
            </>
          )}

          {isLoggedIn && (
            <Button
              onClick={() => {
                signOut();
              }}
              bg="green.400"
              _hover={{
                bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
              }}
            >
              Log Out
            </Button>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Show above="md">
        <Flex
          direction="row"
          justify="space-between"
          alignItems="center"
          p="4"
          bg={isTransparent ? "transparent" : "#EAEDF0"}
          position="fixed"
          w="full"
          zIndex="popover"
          px={{ base: 4, md: 20 }}
        >
          <Link to="/">
            <Img src={unmasked_logo} w="12.5em" minW="130px" />
          </Link>
          {showDesktopMenu()}
        </Flex>
      </Show>

      <Show below="md">
        <Flex
          direction="row"
          justify="space-between"
          alignItems="center"
          p="4"
          bg={isTransparent ? "transparent" : "#EAEDF0"}
          position="fixed"
          w="full"
          px="1.25em"
          zIndex={isOpen ? "0" : 100}
        >
          <Link to="/">
            <Box>
              <Img src={unmasked_logo} w="120px" px="1.25em" />
            </Box>
          </Link>
          <Spacer />
          <IconButton aria-label="" bg="transparent" onClick={onOpen}>
            <GiHamburgerMenu />
          </IconButton>
          {showMobileMenu()}
        </Flex>
      </Show>
    </>
  );
}
