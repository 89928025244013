import React, { useState, createContext, useMemo } from "react";

export type AuthUser = {
  userId: string;
  email: string;
  phoneNumber: string;
  firstname: string;
  lastname: string;
  userRoleId: string;
  userType: string;
};

type UserContextType = {
  user: AuthUser | null;
  setUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
};

type UserContextProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext({} as UserContextType);

function AuthContextProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useState<AuthUser | null>(null);
  const userAuthValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <AuthContext.Provider value={userAuthValue}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContextProvider, AuthContext };
