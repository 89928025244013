import { useContext, useLayoutEffect, useState } from "react";
import { AuthContext, AuthUser } from "../context/authentication/AuthContext";
import convertTokenToUser from "../utils/jwt";

function useAuth() {
  const AUTH_TOKEN_KEY = "authToken";
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<AuthUser | null>(null);

  const { user, setUser } = useContext(AuthContext);

  const signIn = function loginUser(token: string) {
    if (isLoggedIn && loggedInUser) return;

    try {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      const mUser = convertTokenToUser(token);

      if (mUser) {
        setLoggedInUser(user);
        setLoggedIn(true);
        setUser(mUser);
      }
    } catch (error) {
      setLoggedIn(false);
    }
  };

  const getToken = function getLocalToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  };

  const signOut = function logoutUser() {
    localStorage.clear();
    setUser(null);
  };

  const isOnDashboard = () => {
    return window.location.href.indexOf("dashboard") > -1;
  };

  useLayoutEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    if (!isOnDashboard()) return;

    if (!token || token === null) {
      window.location.href = "/";
    } else {
      signIn(token as string);
    }
  });

  return { signIn, signOut, isLoggedIn, loggedInUser, getToken };
}

export default useAuth;
