import { useEffect, useState } from "react";
import useSWR from "swr";
import {
  Container,
  Flex,
  FormControl,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Avatar,
  AvatarBadge,
  Center,
  Box,
  Divider,
} from "@chakra-ui/react";
import { PeopleBackedDetails } from "../../Types";
import { getBorrowerById } from "../../../api/api";
import Loader from "../../Loader";
import { LoanStatus } from "../../../utils/loanStatusEnum";

type BorrowerDetailsProps = {
  borrowerAccountId: string;
  isDisplayed: boolean;
  onClose: () => void;
};

export default function ViewPeopleBacked(props: BorrowerDetailsProps) {
  const { borrowerAccountId, isDisplayed, onClose } = props;
  const { data: borrowersData, error } = useSWR(
    `${borrowerAccountId}`,
    getBorrowerById,
  );

  const [borrowerDetails, setBorrowerDetails] =
    useState<PeopleBackedDetails>(borrowersData);

  useEffect(() => {
    if (borrowersData && borrowersData) {
      setBorrowerDetails(borrowersData);
    }
  }, [borrowersData, error]);

  const getAllPaidLoans = () => {
    if (borrowerDetails.loanInfo) {
      const loansPaidInWeeks = borrowerDetails.loanInfo.filter(
        (loan) =>
          loan.loanStatus.status === LoanStatus.PAID ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_ONE ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_TWO ||
          loan.loanStatus.status === LoanStatus.PAID_WEEK_THREE,
      );

      return loansPaidInWeeks.length;
    }
    return 0;
  };

  const getPaidBackedLoans = () => {
    if (borrowerDetails.loansBacked) {
      const loansPaidInWeeks = borrowerDetails.loansBacked.filter(
        (loan) =>
          loan.loan.loanStatus.status === LoanStatus.PAID ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_ONE ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_TWO ||
          loan.loan.loanStatus.status === LoanStatus.PAID_WEEK_THREE,
      );

      return loansPaidInWeeks.length;
    }
    return 0;
  };

  return (
    <Modal
      isOpen={isDisplayed}
      onClose={() => {
        onClose();
      }}
      size="xl"
    >
      <Container>
        <ModalOverlay />
        <ModalContent
          border="1px dashed #6D7886"
          bgColor="#fff"
          textColor="#000"
          pb={4}
        >
          <ModalHeader textAlign="center">User Profile</ModalHeader>
          <ModalCloseButton />
          {!borrowerDetails && !error && <Loader />}
          {error && <Text>Unable to fetch user details</Text>}
          {borrowerDetails && borrowerDetails && (
            <ModalBody pb={6}>
              <Stack p={4} rounded="lg">
                <>
                  <Flex alignItems="center" justify="center">
                    <Flex
                      h="fit-content"
                      w="fit-content"
                      rounded="full"
                      p={1}
                      bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
                    >
                      <Avatar
                        size="lg"
                        color="#FFFFFF"
                        name={`${borrowerDetails.borrower.borrowerAccount.user.firstname} ${borrowerDetails.borrower.borrowerAccount.user.lastname}`}
                        bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
                      >
                        <AvatarBadge
                          boxSize="0.8em"
                          borderColor="green.500"
                          bg="green.500"
                        />
                      </Avatar>
                    </Flex>
                  </Flex>
                  <Flex direction="row" justify="center">
                    <Text textAlign="center" pr={3} fontWeight="bold">
                      {borrowerDetails.borrower.borrowerAccount.user.firstname}
                    </Text>
                    <Text textAlign="center" fontWeight="bold">
                      {borrowerDetails.borrower.borrowerAccount.user.lastname}
                    </Text>
                  </Flex>
                  <Box w="full" alignItems="center" rounded="md" p={2}>
                    <Center>
                      <Text
                        bgClip="text"
                        fontSize="5xl"
                        fontWeight="bold"
                        bgGradient="linear-gradient(90deg, #00BD9D 0%, #E25822 100%)"
                      >
                        {borrowerDetails.borrower.borrowerAccount.backerPoints}
                        TP
                      </Text>
                    </Center>
                    <Text
                      fontSize="sm"
                      textAlign="center"
                      fontWeight={600}
                      color="#737679"
                    >
                      Trust Points
                    </Text>
                  </Box>
                </>
                <Divider />
                <Text
                  fontSize="md"
                  textAlign="center"
                  color="#737679"
                  fontWeight="bold"
                  pt={4}
                >
                  Loans Information
                </Text>
                <Stack direction="row" mb={4} spacing="1.475em">
                  <FormControl>
                    <Text>Loans Taken: </Text>
                  </FormControl>
                  <FormControl>
                    <Text>{borrowerDetails.loanInfo.length}</Text>
                  </FormControl>
                </Stack>
                <Stack direction="row" mb={4} spacing="1.475em">
                  <FormControl>
                    <Text>Loans Paid: </Text>
                  </FormControl>
                  <FormControl>
                    <Text>{getAllPaidLoans()}</Text>
                  </FormControl>
                </Stack>
                <Stack direction="row" mb={4} spacing="1.475em">
                  <FormControl>
                    <Text>Loans Backed: </Text>
                  </FormControl>
                  <FormControl>
                    <Text>{borrowerDetails.loansBacked.length}</Text>
                  </FormControl>
                </Stack>
                <Stack direction="row" mb={4} spacing="1.475em">
                  <FormControl>
                    <Text>Paid Backings: </Text>
                  </FormControl>
                  <FormControl>
                    <Text>{getPaidBackedLoans()}</Text>
                  </FormControl>
                </Stack>
              </Stack>
            </ModalBody>
          )}
        </ModalContent>
      </Container>
    </Modal>
  );
}
