import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  HStack,
  Avatar,
  AvatarBadge,
  VStack,
  Menu,
  MenuButton,
  Heading,
  MenuList,
  MenuItem,
  Hide,
} from "@chakra-ui/react";
import { BsPencil } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import useAuth from "../../../hooks/useAuth";

function DashboardNavbar({
  username,
  signOut,
}: {
  username: string | undefined;
  signOut: () => void;
}) {
  const { loggedInUser } = useAuth();

  const redirectUser = () => {
    if (loggedInUser?.userType === "Agent") {
      window.location.href = "/agent-dashboard/agent-profile";
      return;
    }

    if (loggedInUser?.userType === "Admin") {
      window.location.href = "/admin-dashboard/admin-profile";
      return;
    }

    window.location.href = "/dashboard/profile";
  };

  return (
    <Box
      position="fixed"
      h="75px"
      w="full"
      px="6"
      py="5"
      border="1px solid #c3cade"
      bg="white"
      zIndex={200}
    >
      <Flex justifyContent="end" w="full">
        <Hide>
          <IconButton
            icon={<IoMdNotificationsOutline size="1.4em" />}
            aria-label=""
            bg="#EAEDF0"
            _hover={{ bg: "green.400" }}
          />
        </Hide>
        <HStack ml="3">
          <Avatar name={username} size="sm">
            <AvatarBadge boxSize="1.25em" bg="green.500" />
          </Avatar>
          <VStack spacing={0} px="1">
            <Menu>
              <MenuButton w="full" as="button" data-testid="">
                <HStack>
                  <Box>
                    <Heading w="full" fontSize="sm" textAlign="left">
                      {username}
                    </Heading>
                  </Box>
                  <ChevronDownIcon color="green.400" />
                </HStack>
              </MenuButton>
              <MenuList bg="#EAEDF0" border="0px">
                <MenuItem
                  _hover={{ bg: "#EAEDF0" }}
                  _focus={{ bg: "#EAEDF0" }}
                  onClick={() => {
                    redirectUser();
                  }}
                >
                  <IconButton
                    icon={<BsPencil size="1.4em" />}
                    aria-label=""
                    bg="#EAEDF0"
                    _hover={{ bg: "green.400" }}
                  />
                  Profile
                </MenuItem>
                <MenuItem
                  _hover={{ bg: "#EAEDF0" }}
                  _focus={{ bg: "#EAEDF0" }}
                  onClick={() => signOut()}
                >
                  <IconButton
                    icon={<FiLogOut size="1.4em" />}
                    aria-label=""
                    bg="#EAEDF0"
                    _hover={{ bg: "green.400" }}
                  />
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
          </VStack>
        </HStack>
      </Flex>
    </Box>
  );
}

export default DashboardNavbar;
