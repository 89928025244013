import { useDisclosure } from "@chakra-ui/hooks";
import {
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Heading,
} from "@chakra-ui/react";
import { useRef } from "react";

export type DialogProps = {
  handleClick: () => void;
};

export function AlertDialogBox(props: DialogProps) {
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: true,
  });
  const cancelRef = useRef<HTMLButtonElement>(null);

  const { handleClick } = props;

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      motionPreset="slideInBottom"
      size="lg"
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            textAlign="center"
            color="#737679"
          >
            <Text>Pending account verification and profile update!</Text>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Heading fontSize="lg" py={4} color="#737679">
              Please verify your account and update your profile to continue.
            </Heading>
            <Text>
              Complete Profile Verification: Please update your profile
              information, submit your valid student ID number and student ID
              picture for admin review to unleash the full potential of the app.
            </Text>
            <Text py={4}>
              <Text
                as="span"
                position="relative"
                fontWeight="semibold"
                color="#737679"
              >
                NOTE: &nbsp;
              </Text>
              <Text as="span">
                If your profile is updated, kindly await admin verification of
                your submitted information. An email will be dispatched to you
                upon completion of admin verification.
              </Text>
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              size="sm"
              bg="green.400"
              onClick={() => {
                handleClick();
              }}
              _hover={{
                bgGradient: "linear-gradient(90deg, #00BD9D 0%, #E25822 100%)",
                textColor: "white",
              }}
              ml={3}
            >
              Proceed to Verification
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
